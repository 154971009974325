import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faHome,
  faUser,
  faUserPlus,
  faSignInAlt,
  faSignOutAlt,
  faFile,
  faPlus,
  faSpinner,
  faFilePdf,
  faPen,
  faTrash
} from "@fortawesome/free-solid-svg-icons";

library.add(faHome, faUser, faUserPlus, faSignInAlt, faSignOutAlt,faFile,faPlus,faSpinner,faFilePdf,faPen,faTrash);

export { FontAwesomeIcon };