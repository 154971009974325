import { createWebHistory, createRouter } from "vue-router";
import Login from "@/components/Login.vue";
//import Register from "@/components/Register.vue";
// lazy-loaded
const LlistatInformes = () => import("@/components/LlistatInformes.vue")
const Informe = () => import("@/components/Informe.vue")
const BoardUser = () => import("@/components/BoardUser.vue")

const routes = [
  {
    path: "/",
    name: "home",
    component: LlistatInformes,
  },
  {
    path: "/informes",
    name: "informes",
    component: LlistatInformes,
  },
  {
    path: "/informe/:id",
    name: "informe",
    component: Informe,
  },
  {
    path: "/login",
    component: Login,
  },
  {
    path: "/user",
    name: "user",
    // lazy-loaded
    component: BoardUser,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
    const publicPages = ['/login'];
    const authRequired = !publicPages.includes(to.path);
    const loggedIn = localStorage.getItem('user');
    // trying to access a restricted page + not logged in
    // redirect to login page
    if (authRequired && !loggedIn) {
      next('/login');
    } else {
      next();
    }
  });
export default router;